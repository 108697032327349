<template>
  <v-list-item
    dense
    :style="indent"
    :class="[single ? 'no-indent' : '', depth == 0 ? 'px-0' : '']"
  >
    <v-list-item-content>
      <div style="display: block" class="grey lighten-5 py-1">
        <v-list-item-subtitle class="d-flex justify-space-between px-3">
          <router-link
            class="caption"
            :class="
              isSameUser
                ? `deep-purple--text text--lighten-2 text-capitalize`
                : `secondary--text text--lighten-2 text-capitalize`
            "
            :to="`/u/${comment.author}`"
            >{{ isSameUser ? 'you' : comment.author }}
            <v-icon v-if="isSameUser" small color="deep-purple">
              {{ mdiFace }}
            </v-icon>
          </router-link>
          <span class="caption grey--text">{{ comment.duration }} ago</span>
        </v-list-item-subtitle>

        <v-list-item-content class="px-3">
          <div class="mb-2" v-if="quote && single">
            <!-- Reply menu -->
            <quoted-reply :quote="quote"></quoted-reply>
          </div>
          <div class="font-weight-medium">
            {{ comment.body }}
          </div>
        </v-list-item-content>

        <v-list-item-action
          class="ma-0 py-0 px-0 d-flex flex-row align-items-center justify-space-between"
          :class="depth == 0 ? 'px-1' : ''"
          style="max-height: 100px"
        >
          <div>
            <v-menu v-if="isSameUser" top>
              <template v-slot:activator="{ on }">
                <v-btn small @click.stop icon v-on="on">
                  <v-icon small color="secondary lighten-1">
                    {{ mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list tile dense>
                <v-list-item
                  dense
                  v-if="isSameUser"
                  @click="deleteComment(comment.id)"
                >
                  <v-list-item-icon>
                    <v-icon small color="error lighten-1">
                      {{ mdiDelete }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>

                <v-list-item
                  dense
                  v-if="isSameUser"
                  @click.stop="
                    editComment({
                      id: comment.id,
                      body: comment.body
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small color="secondary lighten-1">
                      {{ mdiPencil }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-if="comment.replies_count > 0 && depth < 6"
              small
              text
              :loading="fetchingReplies"
              :color="expand ? 'secondary ligten-2' : 'primary'"
              class="caption ml-2"
              @click.stop="expand ? (expand = false) : getReplies()"
              >{{
                !expand ? comment.replies_count + ' replies' : 'hide replies'
              }}</v-btn
            >

            <v-btn
              v-if="depth > 5 && comment.replies_count > 1"
              small
              text
              :loading="fetchingReplies"
              color="indigo"
              class="caption ml-2"
              @click.stop="openThread(comment)"
              >Continue thread</v-btn
            >
          </div>
          <!-- If the reply is going to create unnecessary depth just reply in the main comment... -->
          <div class="d-flex">
            <v-btn
              icon
              v-if="loggedIn"
              color="primary"
              @click.stop="
                depth < 6
                  ? replyComment(
                      {
                        id: comment.id,
                        body: comment.body,
                        author: comment.author
                      },
                      comment
                    )
                  : openThread(comment)
              "
            >
              <v-icon
                small
                color="
              secondary lighten-2
              "
              >
                {{ mdiReplyOutline }}
              </v-icon>
            </v-btn>
            <div>
              <v-btn
                icon
                :color="circleColor || 'primary'"
                @click.stop="user_upvoted ? undo('upvote') : vote('upvote')"
              >
                <v-icon
                  small
                  :color="
                    user_upvoted
                      ? `${circleColor || 'primary darken-2'}`
                      : 'secondary lighten-2'
                  "
                >
                  {{ mdiThumbUpOutline }}
                </v-icon>
              </v-btn>
              <v-list-item-action-text class="subtitle-2">{{
                upvotes + downvotes
              }}</v-list-item-action-text>

              <v-btn
                icon
                color="secondary lighten-2"
                @click.stop="
                  user_downvoted ? undo('downvote') : vote('downvote')
                "
              >
                <v-icon
                  small
                  :color="
                    user_downvoted ? 'warning darken-2' : 'secondary lighten-2'
                  "
                >
                  {{ mdiThumbDownOutline }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-list-item-action>
      </div>

      <template v-if="expand && comment.replies && !insideThread">
        <v-list-item-content class="py-0">
          <v-list
            dense
            flat
            tile
            :class="[!singleReply ? 'reply' : '']"
            style="border-radius: 0 !important"
          >
            <comment
              v-for="c in comment.replies"
              :key="c.id"
              :comment="c"
              :ref="`comment-${c.id}`"
              :circleColor="circleColor"
              :depth="depth + 1"
              :quote="{ body: comment.body, author: comment.author }"
              :single="singleReply"
              :reply-comment="replyComment"
              :edit-comment="editComment"
              :open-thread="openThread"
              :insideThread="insideThread"
              @comment-deleted="commentDeleted"
            ></comment>
          </v-list>
        </v-list-item-content>
      </template>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import http from '@/plugins/http';
import QuotedReply from './quoted-reply.vue';
// icons
import {
  mdiFace,
  mdiDelete,
  mdiDotsVertical,
  mdiPencil,
  mdiReplyOutline,
  mdiThumbUpOutline,
  mdiThumbDownOutline
} from '@mdi/js';
export default {
  name: 'Comment',
  components: {
    QuotedReply
  },
  props: {
    comment: {
      type: Object
    },
    circleColor: {
      type: String
    },
    postAuthor: {
      type: String
    },
    depth: {
      type: Number
    },
    single: {
      type: Boolean
    },
    replyComment: {
      type: Function
    },
    editComment: {
      type: Function
    },
    openThread: {
      type: Function
    },
    quote: {
      type: Object
    },
    insideThread: {
      type: Boolean
    }
  },
  data() {
    return {
      upvotes: this.comment.upvotes || 0,
      downvotes: this.comment.downvotes || 0,
      user_upvoted: this.comment.current_user_has_upvoted || false,
      user_downvoted: this.comment.current_user_has_downvoted || false,
      editingComment: false,
      updateLoading: false,
      update: '',
      expand: false,
      fetchingReplies: false,
      mdiFace,
      mdiDelete,
      mdiDotsVertical,
      mdiPencil,
      mdiReplyOutline,
      mdiThumbUpOutline,
      mdiThumbDownOutline
    };
  },
  computed: {
    // TODO: move this to a mixin...
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
    isSameUser: function() {
      if (this.loggedIn) {
        return (
          this.$store.getters.user.username.trim().toLowerCase() ==
          this.comment.author.trim().toLowerCase()
        );
      }

      return false;
    },
    isAuthor: function() {
      if (this.loggedIn) {
        return (
          this.$store.getters.user.username.trim().toLowerCase() ==
          this.comment.author.trim().toLowerCase()
        );
      }

      return false;
    },
    singleReply() {
      return this.comment.replies_count == 1;
    },
    indent() {
      // return { transform: `translate(${this.depth * 5}px)` };
      return { 'padding-left': `${this.depth * 5}px` };
    },
    newCommentReply() {
      return this.$store.getters.newCommentReply;
    },
    newCommentUpdate() {
      return this.$store.getters.newCommentUpdate;
    },
    newComment() {
      return this.$store.getters.comment;
    }
  },
  watch: {
    'comment.upvotes': function(val) {
      this.upvotes = val;
    },
    'comment.downvotes': function(val) {
      this.downvotes = val;
    },
    'comment.current_user_has_upvoted': function(val) {
      this.user_upvoted = val;
    },
    'comment.current_user_has_downvoted': function(val) {
      this.user_downvoted = val;
    },
    newCommentReply: function(val) {
      if (val) {
        // Check if it's for you...
        if (this.newComment.parent_comment_id == this.comment.id) {
          console.log('Updating reply!');
          // For me oya add this to replies...
          // this.comment.replies = !this.comment.replies
          //   ? [this.newComment]
          //   : [...this.comment.replies, this.newComment];
          this.comment.replies_count++;
          this.getReplies();
          this.expand = true;
        }
      }
    },
    newCommentUpdate: function(val) {
      console.log('Update!');
      if (val) {
        // Check if it's for you...
        if (this.newComment.id == this.comment.id) {
          // For me oya add this to replies...
          this.comment.body = this.newComment.body;
          // this.comment.replies = this.comment.replies_count == 1 ? [this.newComment] : [...this.comment.replies, this.newComment]
        }
      }
    }
  },
  methods: {
    deleteComment(id) {
      let confirm = window.confirm(
        'niqqa, sure you wanna delete this comment?'
      );

      if (confirm) {
        const token =
          this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
        const headers = {
          Authorization: `token ${token}`
        };
        http
          .delete(`/v1/post/comments/${id}`, { headers })
          .then(response => {
            if (response.data.success) {
              this.$emit('comment-deleted', id);
            }
          })
          .catch(response => {
            this.$store.dispatch('SHOW_TOAST', {
              message: `Error deleting comment`,
              style: 'error'
            });
            console.log('Error deleting comment ! =>', response);
          });
      }
    },
    commentDeleted(id) {
      this.removeComment(id);
      this.$store.dispatch('SHOW_TOAST', {
        message: 'Comment deleted successfully',
        style: 'info'
      });
    },
    removeComment(id) {
      this.comment.replies_count--;

      let index = this.comment.replies.findIndex(co => co.id == id);

      this.comment.replies.splice(index, 1);
    },
    vote(type) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };

      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      // Client server trick
      switch (type) {
        case 'upvote':
          this.upvotes++;
          this.downvotes += this.user_downvoted ? 1 : 0;
          this.user_upvoted = true;
          this.user_downvoted = false;
          break;
        case 'downvote':
          this.downvotes--;
          this.upvotes -= this.user_upvoted ? 1 : 0;
          this.user_downvoted = true;
          this.user_upvoted = false;
          break;
        default:
          break;
      }

      http
        .post(
          `/v1/post/comments/${this.comment.id}/${type}/`,
          { sturves: true },
          { headers }
        )
        .then(response => {
          console.log(`Success performing ${type} on comment`, response.data);
          switch (type) {
            case 'upvote':
              this.user_upvoted = true;
              this.user_downvoted = false;
              break;
            case 'downvote':
              this.user_downvoted = true;
              this.user_upvoted = false;
              break;
            default:
              break;
          }
          this.upvotes = response.data.data.upvotes;
          this.downvotes = response.data.data.downvotes;
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: `Error performing ${type} on comment`,
            style: 'error'
          });
          console.log(`Error performing ${type} on comment`, response.data);
        });
    },
    undo(type) {
      const headers = {
        Authorization: `token ${this.$store.getters.token}`
      };
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      http
        .delete(`/v1/post/comments/${this.comment.id}/${type}/`, { headers })
        .then(response => {
          console.log(`Success undoing ${type} on comment`, response.data);
          switch (type) {
            case 'upvote':
              this.user_upvoted = false;
              break;
            case 'downvote':
              this.user_downvoted = false;
              break;
            default:
              break;
          }
          this.upvotes = response.data.data.upvotes;
          this.downvotes = response.data.data.downvotes;
        })
        .catch(response => {
          console.log(`Error undoing ${type}`, response.data);
        });
    },
    updateComment(id) {
      this.updateLoading = true;
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      http
        .patch(`/v1/post/comments/${id}`, { body: this.update }, { headers })
        .then(response => {
          console.log('Respones =>', response.data);
          this.comment.body = response.data.data.body;
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couldn't update comment please try again :/",
            style: 'error'
          });
          console.log('Error updating comment ! =>', response);
        })
        .finally(() => {
          this.updateLoading = false;
          this.editingComment = false;
        });
    },
    getReplies() {
      if (this.comment.replies_count > 0) {
        this.fetchingReplies = true;
        const token =
          this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
        const headers = {
          Authorization: `token ${token}`
        };
        http
          .get(`/v1/post/comments/${this.comment.id}/replies/`, { headers })
          .then(response => {
            // this.comments = response.data;
            if (response.data) {
              this.$set(this.comment, 'replies', response.data.results);
              this.expand = true;
            }
          })
          .catch(response => {
            this.$store.dispatch('SHOW_TOAST', {
              message: "Couldn't get replies :/",
              style: 'error'
            });
            console.log('Error getting replies to comment ! =>', response);
          })
          .finally(() => {
            this.fetchingReplies = false;
          });
      }
    },
    cancelEdit() {
      this.editingComment = false;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.criticism {
  color: white;
  background-color: rgba(219, 68, 55, 0.99);
}

.praise {
  color: white;
  background-color: rgba(0, 150, 136, 0.99);
}

.chip-text {
  font-size: 0.7rem;
}

/** replies */
.reply {
  border-left: 1px solid #d3d3d3;
}

.no-indent {
  transform: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* .no-indent .v-list-item:first-of-type {
  transform: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.no-indent .v-list:first-of-type {
  transform: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
