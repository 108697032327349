<template>
  <v-menu top transition="scale-transition" origin="top left" v-model="open">
    <template v-slot:activator="{ on }">
      <v-chip v-on="on" color="pink lighten-4" style="width: 100%">
        <v-avatar class="white--text" size="15px" color="pink lighten-2">
          {{ quote.author ? quote.author.charAt(0).toUpperCase() : 'N/A' }}
        </v-avatar>
        <span class="ml-2">{{
          $options.filters.truncate(quote.body, 30, '...')
        }}</span>
      </v-chip>
    </template>

    <v-card>
      <v-card-subtitle class="d-flex caption align-center">
        <span> {{ quote.author }} </span>
        <v-spacer></v-spacer>

        <v-btn icon @click="open = false">
          <v-icon>
            {{ mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-subtitle>

      <v-card-text>
        <p class="ma-0 body-2 font-weight-bold secondary--text">
          {{ quote.body }}
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
// icons
import { mdiClose } from '@mdi/js';
export default {
  name: 'QuotedReply',
  props: ['quote'],
  data: function() {
    return {
      open: false,
      mdiClose
    };
  }
};
</script>
