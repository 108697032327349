<template>
  <div id="viewpost">
    <v-app-bar app dark :color="circleMainColor">
      <v-btn icon @click="back">
        <v-icon color="white"> {{ mdiArrowLeft }} </v-icon>
      </v-btn>

      <v-toolbar-title class="pl-0">
        <template v-if="loading">
          <v-skeleton-loader
            :loading="loading"
            class="mb-1"
            width="100px"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            :loading="loading"
            width="180px"
            type="text"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <div class="d-flex flex-column">
            <small style="font-size: .8rem">Posted in</small>
            <p
              class="text-capitalize font-weight-bold mb-0 d-flex align-center"
              style="font-size: 1rem"
            >
              <router-link :to="`/c/${circle.slug}`" tag="span">
                <v-avatar
                  v-if="circle.display_picture"
                  color="white"
                  size="23"
                  class="mr-1"
                >
                  <v-img width="23" height="23" :src="circle.display_picture">
                  </v-img>
                </v-avatar>
                {{ circle.name }}
              </router-link>
            </p>
          </div>
        </template>
      </v-toolbar-title>
    </v-app-bar>

    <div class="px-3" v-if="loading">
      <v-skeleton-loader
        :loading="loading"
        width="100%"
        type="text"
      ></v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        height="300px"
        type="card"
      ></v-skeleton-loader>
    </div>

    <!-- TODO: Improve skeleton loaders -->
    <template v-else>
      <post
        :post="post"
        :insideViewPost="true"
        :circleColor="circleMainColor"
        @post-deleted="handleDelete"
      >
        <template #comment v-if="loggedIn">
          <div class="flex-fill" id="comment-input">
            <v-btn
              block
              depressed
              outlined
              color="pink accent-4"
              class="px-2 py-1"
              @click="openCommentEditor = true"
            >
              Comment
            </v-btn>
          </div>
        </template>
      </post>
    </template>

    <div>
      <div
        class="grey lighten-3
caption px-3 py-2 font-rubik"
      >
        {{ post.comments }} Comments
      </div>

      <v-list
        v-if="comments.length > 0"
        dense
        tile
        flat
        class="py-0"
        style="border-radius: 0 !important"
      >
        <comment
          v-for="c in comments"
          :key="c.id"
          :depth="0"
          :comment="c"
          :circleColor="circleMainColor"
          :reply-comment="replyComment"
          :edit-comment="editComment"
          :open-thread="openThread"
          :quote="{ body: c.body, author: c.author }"
          @comment-deleted="commentDeleted"
          @reply-comment="replyComment"
        ></comment>
      </v-list>

      <v-card
        v-else
        color="pink lighten-4"
        class="ma-3 elevation-0"
        @click="openCommentEditor = true"
        height="100px"
      >
        <v-card-text
          class="text-center d-flex align-center"
          style="width: 100%; height: 100%"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <span class="text--black font-weight-bold" style="font-size: 18px"
                >Be the first to comment 🌝
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <v-bottom-sheet
      v-model="openCommentEditor"
      :fullscreen="fullscreenEditor"
      persistent
      :retain-focus="false"
      transition="dialog-bottom-transition"
    >
      <comment-editor
        v-if="openCommentEditor"
        @close-editor="closeCommentEditor"
        @fullscreen="fullscreenEditor = !fullscreenEditor"
        :loading="commentLoading"
        :comment="currentComment"
        :editing="editingComment"
        :method="method"
      ></comment-editor>
    </v-bottom-sheet>

    <!-- Thread viewer -->
    <v-dialog
      v-model="openThreadViewer"
      hide-overlay
      fullscreen
      persistent
      :retain-focus="false"
      transition="dialog-bottom-transition"
    >
      <thread-reader
        @close-thread="openThreadViewer = false"
        :thread="thread"
        :circleColor="circleMainColor"
        :depth="0"
        :reply-comment="replyComment"
        :edit-comment="editComment"
        :open-thread="openThread"
        @comment-deleted="commentDeleted"
        @reply-comment="replyComment"
      ></thread-reader>
    </v-dialog>
  </div>
</template>

<script>
import http from '@/plugins/http';
import Post from '@/components/post/post.vue';
import Comment from '@/components/post/comment/comment.vue';
import backer from '@/mixins/router-back';
import ThreadReader from '../../components/helpers/thread-reader.vue';
import CommentEditor from '@/components/post/comment/comment-editor.vue';
// Icons
import { mdiArrowLeft } from '@mdi/js';

export default {
  name: 'ViewPost',
  components: {
    Post,
    Comment,
    CommentEditor,
    ThreadReader
  },
  props: {
    circle: {
      type: Object
    }
  },
  mixins: [backer],
  data: () => ({
    post: {},
    loading: true,
    // Icons  --- /
    mdiArrowLeft,
    postCommentLoading: false,
    openCommentEditor: false,
    fullscreenEditor: false,
    commentLoading: false,
    comment: '',
    currentComment: {
      id: '',
      commenter: '',
      body: ''
    },
    method: 'create',
    c: {},
    editingComment: false,
    comments: [],
    showOptions: false,
    thread: '',
    openThreadViewer: false
  }),
  computed: {
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
    circleStyles() {
      if (this.circle.styles) {
        return JSON.parse(this.circle.styles);
      }

      return { mainColor: 'primary' };
    },
    circleMainColor() {
      if (this.circle.styles) {
        return this.circleStyles.mainColor;
      }

      return 'primary';
    }
  },
  methods: {
    getPost(slug) {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      if (token) {
        http
          .get(`/v1/post/${slug}/`, { headers })
          .then(response => {
            this.post = response.data.data;
          })
          .catch(error => {
            console.log('Error ! =>', error);

            if (error.response.status == 404) {
              this.$router.push({ name: '404', query: { lost: 'post' } });
              return;
            }

            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error fetching Post',
              style: 'error'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        http
          .get(`/v1/post/${slug}/`)
          .then(response => {
            this.post = response.data.data;
          })
          .catch(error => {
            console.log('Error ! =>', error);
            if (error.response.status == 404) {
              this.$router.push({
                name: '404',
                query: { lost: 'post' },
                params: { '0': this.$route.path }
              });
              return;
            }
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error fetching Post',
              style: 'error'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    commentDeleted(id) {
      this.removeComment(id);
      this.$store.dispatch('SHOW_TOAST', {
        message: 'Comment deleted successfully',
        style: 'info'
      });
    },
    getComments(slug) {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      if (token) {
        http
          .get(`/v1/post/${slug}/comments/`, { headers })
          .then(response => {
            this.comments = response.data.results;
          })
          .catch(response => {
            console.log('Error fetching comments ! =>', response);
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error fetching comments',
              style: 'error'
            });
          });
      } else {
        http
          .get(`/v1/post/${slug}/comments/`)
          .then(response => {
            this.comments = response.data.results;
          })
          .catch(response => {
            console.log('Error fetching comments ! =>', response);
            this.$store.dispatch('SHOW_TOAST', {
              message: "Couldn't fetch comments :/",
              style: 'error'
            });
          });
      }
    },
    async closeCommentEditor(text) {
      if (text.text) {
        console.log(text);
        this.commentLoading = true;
        /** Add a loader that closese the editor after this is successful */
        let res;
        if (text.edit) {
          res = await this.updateComment(this.currentComment.id, text.text);
        } else {
          res = await this.postComment({
            ...text,
            comment_id: this.currentComment.id
          });
        }

        if (res) {
          console.log(res);
        }
        this.method = 'create';

        this.openCommentEditor = false;
        this.commentLoading = false;
        return;
      }
      this.method = 'create';
      this.openCommentEditor = false;
    },
    replyComment(comment, c) {
      this.method = 'reply';
      this.currentComment = comment;
      this.c = c;
      this.openCommentEditor = true;
    },
    editComment(comment) {
      this.method = 'edit';
      this.editingComment = true;
      this.currentComment = comment;
      this.openCommentEditor = true;
    },
    openThread(comment) {
      this.thread = comment;
      this.openThreadViewer = true;
    },
    postComment({ text, reply, comment_id }) {
      if (text == '') {
        return alert('yo, please write something in the comment box');
      }

      this.postCommentLoading = true;
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };

      const slug = this.$route.params['post_slug'];

      let comment = { body: text };
      if (reply) {
        comment.parent_comment_id = comment_id;
      }

      alert(comment);

      return http
        .post(`/v1/post/${slug}/comment/`, comment, { headers })
        .then(response => {
          console.log('Comment created successfully!', response.data.data);

          if (!reply) {
            // Here find the comment that is being replied to and append it to the parent...
            // this.parentCommentIndex = this.comments.
            this.comments.unshift(response.data.data);
            // this.comments.push(response.data.data);
          } else {
            // If this isn't a reply we can append it to the parent...
            // we can do that by finding the parent or just using the ref...
            // Let's try ref's
            this.$store.dispatch('NEW_COMMENT_REPLY', response.data.data);
          }

          this.$store.dispatch('SHOW_TOAST', {
            message: 'Comment created successfully',
            style: 'success'
          });
          return true;
        })
        .catch(response => {
          console.log('Error ! =>', response);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Problem creating comment',
            style: 'error'
          });
        })
        .finally(() => {
          this.postCommentLoading = false;
          this.comment = '';
          this.commentLabel = null;
          this.$store.dispatch('TOGGLE_COMMENT_OPTION', 'newCommentReply');
        });
    },
    updateComment(id, text) {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      return http
        .patch(`/v1/post/comments/${id}/`, { body: text }, { headers })
        .then(response => {
          console.log('Respones =>', response.data);
          this.$store.dispatch('NEW_COMMENT_UPDATE', response.data.data);
          return true;
        })
        .catch(response => {
          console.log('Error updating comment ! =>', response);
          this.$store.dispatch('SHOW_TOAST', {
            message: "Couln't update comment :/",
            style: 'error'
          });
        })
        .finally(() => {
          this.$store.dispatch('TOGGLE_COMMENT_OPTION', 'newCommentUpdate');
        });
    },
    handleDelete() {
      this.$router.back();
    },
    removeComment(id) {
      let index = this.comments.findIndex(co => co.id == id);

      this.comments.splice(index, 1);
    }
  },
  mounted() {
    const postSlug = this.$route.params['post_slug'];
    this.getPost(postSlug);
    this.getComments(postSlug);
  },
  head() {
    return {
      title: `'${this.post.caption}' ${this.post.upvotes +
        this.post.downvotes} ${
        this.post.upvotes + this.post.downvotes != 1 ? 'upvotes' : 'upvote'
      } by ${this.post.author}`
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.getPost(to.params.post_slug);
    this.getComments(to.params.post_slug);
    next();
  }
};
</script>

<style scoped>
.v-input input {
  padding: 0.5rem 0.2rem !important;
}

fieldset {
  background-color: #f2f2f2 !important;
}

.post-seperator {
  background-color: #e1e1e1;
  height: 1.5px;
}
</style>
