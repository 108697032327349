<template>
  <div id="wrapper">
    <div id="content">
      <div
        class="transparent d-flex align-center justify-space-between px-3 py-4"
      >
        <v-btn text icon @click="post(false)">
          <v-icon color="secondary">
            {{ mdiClose }}
          </v-icon>
        </v-btn>

        <v-toolbar-title class="caption">{{
          creating
            ? 'Write Comment'
            : editing
            ? 'Edit Comment'
            : 'Reply Comment'
        }}</v-toolbar-title>

        <v-btn
          @click="creating ? post(text) : editing ? edit(text) : reply(text)"
          text
          color="primary"
        >
          {{ creating ? 'Post' : editing ? 'Edit' : 'Reply' }}
        </v-btn>
      </div>

      <v-list-item v-if="replying" class="grey lighten-2 mb-2">
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ comment.author }}
          </v-list-item-subtitle>
          <v-card-text class="px-0">
            {{ comment.body }}
          </v-card-text>
        </v-list-item-content>
      </v-list-item>
      <!-- Text Area! -->
      <div class="mx-3 editor">
        <textarea
          ref="textarea"
          autofocus
          v-model="text"
          v-on:input="autoExpand"
          class="font markdown-input"
        ></textarea>
      </div>

      <!-- Text areaA -->

      <v-footer
        absolute
        color="whitesmoke"
        elevation="0"
        style="z-index: 3"
        class="font-weight-medium fixed elevation-0"
      >
        <div
          class="d-flex align-center justify-space-between"
          elevation="0"
          style="width: 100%"
          dense
        >
          <v-spacer></v-spacer>

          <!-- <v-divider vertical></v-divider> -->
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn-toggle
                v-model="fullscreen"
                group
                background-color="none"
                color="indigo accent-2"
                borderless
                dense
              >
                <v-btn
                  v-on="tooltip"
                  @click="$emit('fullscreen')"
                  :ripple="false"
                  icon
                  :value="true"
                >
                  <v-icon color="secondary" v-if="!fullscreen">
                    {{ mdiFullscreen }}
                  </v-icon>

                  <v-icon color="secondary" v-else>
                    {{ mdiFullscreenExit }}
                  </v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
            Fullscreen
          </v-tooltip>
        </div>
      </v-footer>
    </div>

    <!-- LOADING OVERLAY -->
    <v-overlay :value="loading" absolute>
      <v-progress-circular
        indeterminate
        color="primary"
        size="30"
      ></v-progress-circular>
    </v-overlay>
    <!-- LOADING OVERLAY -->
  </div>
</template>

<script>
// icons
import { mdiClose, mdiFullscreen, mdiFullscreenExit } from '@mdi/js';
export default {
  name: 'CommentEditor',
  props: ['comment', 'loading', 'method'],
  data() {
    return {
      text: this.method == 'edit' ? this.comment.body : '',
      preview: false,
      fullscreen: false,
      mdiClose,
      mdiFullscreen,
      mdiFullscreenExit
    };
  },
  computed: {
    replying() {
      return this.method == 'reply';
    },
    creating() {
      return this.method == 'create';
    },
    editing() {
      return this.method == 'edit';
    }
  },
  methods: {
    post(text) {
      this.$emit('close-editor', { text, reply: false, edit: false });
      this.text = '';
      this.fullscreen = false;
    },
    reply(text) {
      this.$emit('close-editor', { text, reply: true, edit: false });
      this.text = '';
      this.fullscreen = false;
    },
    edit(text) {
      this.$emit('close-editor', { text, reply: false, edit: true });
      this.text = '';
      this.fullscreen = false;
    },
    autoExpand({ target: field }) {
      // Reset field height
      field.style.height = 'inherit';

      // Get the computed styles for the element
      var computed = window.getComputedStyle(field);

      // Calculate the height
      var height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        parseInt(computed.getPropertyValue('padding-top'), 10) +
        field.scrollHeight +
        parseInt(computed.getPropertyValue('padding-bottom'), 10) +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      field.style.height = height + 'px';
    }
  },
  mounted() {
    this.$refs.textarea.focus();
  }
};
</script>
<style scoped>
body {
  overflow-y: hidden;
}

#wrapper:before {
  content: '';
  float: left;
  height: 100%;
}

#wrapper {
  height: 100%;
  background-color: whitesmoke;
}

#content {
  background-color: whitesmoke;
}

#content::after {
  content: '';
  display: block;
  clear: both;
}

.font {
  font-size: 18px !important;
}

.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: transparent !important;
  border: none;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  background-color: transparent !important;
  border: none;
}

.fixed {
  position: fixed !important;
}

.editor {
  position: relative;
}

.markdown-input {
  border: none;
  outline: none;
  z-index: 2;
  position: relative;
  min-height: 325px;
  width: 100%;
}

/* .markdown-display {
  min-height: 200px;
  position: absolute;
  border: none;
  outline: none;
  top: 0;
  width: 100%;
} */
</style>
