<template>
  <v-card tile>
    <v-toolbar tile dark color="indigo">
      <v-btn icon @click="$emit('close-thread')">
        <v-icon color="white">
          {{ mdiClose }}
        </v-icon>
      </v-btn>
      <v-toolbar-title>Viewing thread</v-toolbar-title>
    </v-toolbar>

    <v-sheet color="pink lighten-3" class="mb-2">
      <comment
        :comment="thread"
        :circleColor="circleColor"
        :depth="0"
        :reply-comment="replyComment"
        :edit-comment="editComment"
        :insideThread="true"
      ></comment>
    </v-sheet>

    <v-divider></v-divider>

    <v-card-text class="pa-0 ma-0" v-if="replies.length > 0">
      <comment
        v-for="c in replies"
        :key="c.id"
        :comment="c"
        :circleColor="circleColor"
        :depth="0"
        :reply-comment="replyComment"
        :edit-comment="editComment"
        :insideThread="true"
      ></comment>
    </v-card-text>
  </v-card>
</template>

<script>
import http from '../../plugins/http';
import Comment from '@/components/post/comment/comment.vue';
// icons
import { mdiClose } from '@mdi/js';
export default {
  name: 'ThreadViewer',
  components: {
    Comment
  },
  props: {
    thread: {
      type: Object
    },
    circleColor: {
      type: String
    },
    depth: {
      type: Number
    },
    single: {
      type: Boolean
    },
    replyComment: {
      type: Function
    },
    editComment: {
      type: Function
    },
    openThread: {
      type: Function
    }
  },
  data() {
    return {
      replies: [],
      mdiClose
    };
  },
  watch: {
    'thread.replies_count': function(newv) {
      console.log('Replies have been changed!', newv);
      this.getReplies();
    }
  },
  methods: {
    getReplies() {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      http
        .get(`/v1/post/comments/${this.thread.id}/replies/`, { headers })
        .then(response => {
          // this.threads = response.data;
          if (response.data) {
            console.log('Replies => ', response.data);
            this.replies = response.data.results;
          }
        })
        .catch(response => {
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error fetching replies :/',
            style: 'error'
          });
          console.log('Error deleting comment ! =>', response);
        })
        .finally(() => {
          // this.fetchingReplies = false;
        });
    }
  },
  mounted() {
    this.getReplies();
  }
};
</script>

<style></style>
